/* Container style */
.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
    font-family: Arial, sans-serif;
  }
  
  /* Left section style */
  .left {
    flex: 1;
    padding-right: 20px;
  }
  
  .left h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .left p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  .left p strong {
    font-weight: bold;
  }
  
  .left p em {
    font-style: italic;
    color: #777;
  }
  
  /* Right section style */
  .right {
    flex: 1;
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Button style */
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .filter-container {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  #status-filter {
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  