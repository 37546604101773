/* components/Table/Table.css */

.table-container {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  @media (max-width: 768px) {
    th, td {
      padding: 8px;
      font-size: 0.9rem;
    }
  }
  