/* Accounts.css */
.container {
  padding: 20px;
}

.main-content {
  padding: 20px;
}

.header-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.add-btn {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-btn:hover {
  background-color: #218838;
}

.account-form {
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
}

.account-form .form-group {
  margin-bottom: 15px;
}

.account-form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.account-form input, .account-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form-actions {
  display: flex;
  gap: 10px;
}

.save-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-btn:hover {
  background-color: #0056b3;
}

.cancel-btn {
  background-color: #dc3545;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #c82333;
}

.action-btn {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.edit-btn {
  background-color: #ffc107;
  color: black;
}

.edit-btn:hover {
  background-color: #e0a800;
}

.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  word-wrap: break-word;
}

.table th {
  background-color: #f4f4f4;
}

.table td {
  white-space: normal;
}
