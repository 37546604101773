.dashboard {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
  }
  
  .stats-cards {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
  }
  
  .stat-card {
    background-color: #f4f6f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 200px;
  }
  
  .stat-card h3 {
    font-size: 1.2rem;
    color: #333;
  }
  
  .stat-card p {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .detailed-stats {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .chart {
    flex: 1;
    padding: 20px;
    background-color: #f4f6f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .chart h3 {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .chart ul {
    list-style: none;
    padding: 0;
  }
  
  .chart ul li {
    font-size: 1rem;
    margin: 5px 0;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  table th, table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  table th {
    background-color: #e9ecef;
    font-weight: 600;
  }
  
  table tr:hover {
    background-color: #f1f3f5;
  }
  