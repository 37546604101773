/* Menu container */
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 200px;
    background-color: #1a1a2e;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    transition: width 0.3s ease;
    z-index: 1000;
  }
  
  /* Collapsed sidebar */
  .sidebar.collapsed {
    width: 60px;
  }
  
  /* Hidden sidebar for mobile */
  .sidebar.hidden {
    width: 0;
    overflow: hidden;
  }
  
  /* Sidebar title/logo */
  .sidebar h1 {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }

  .sidebar h1 span.tel {
    color: #21c87a
  }
  
  /* Navigation links */
 /* Navigation links */
.sidebar ul {
    list-style: none;
    padding: 0;
    width: 100%;
    text-align: left; /* Ajoutez ceci pour aligner le texte à gauche */
}

.sidebar ul li a {
    color: white;
    text-decoration: none;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Changez "center" à "flex-start" pour aligner les liens à gauche */
    padding-left: 20px; /* Ajoutez un padding pour espacer les éléments du bord gauche */
    gap: 10px;
}
  
  /* Only show icon when sidebar is collapsed */
  .sidebar.collapsed ul li a span.text {
    display: none;
  }
  
  /* Show icon and text when sidebar is expanded */
  .sidebar ul li a span.icon {
    font-size: 1.5rem;
  }
  
  .sidebar ul li:hover {
    background-color: #16213e;
  }
  
  .sidebar ul li.active a {
    color: #00aaff;
  }
  
  /* Toggle button for desktop */
  .sidebar .toggle-btn {
    position: absolute;
    top: 20px;
    right: -20px;
    width: 40px;
    height: 40px;
    background-color: #1a1a2e;
    color: white;
    border-radius: 50%;
    font-size: 1.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
  }
  
  /* Mobile hamburger menu button */
  .hamburger-btn {
    display: none;
    position: fixed;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    background-color: #1a1a2e;
    color: white;
    border-radius: 50%;
    font-size: 1.5rem;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    z-index: 1001;
  }
  
  /* Main content area */
  .main-content {
    margin-left: 250px;
    padding: 20px;
    transition: margin-left 0.3s ease;
  }
  
  /* Adjust margin when sidebar is collapsed */
  .sidebar.collapsed + .main-content {
    margin-left: 60px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .sidebar {
      width: 0;
    }
  
    .sidebar.collapsed {
      display: none;
    }
  
    .hamburger-btn {
      display: flex;
    }
  
    .main-content {
      margin-left: 0;
    }
  }
  
  .logout-section {
    margin-top: auto;
    padding: 20px;
    text-align: center;
  }
  
  .logout-link {
    color: #28a745; /* Vert moderne */
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    transition: color 0.3s ease;
  }
  
  .logout-link:hover {
    color: #218838; /* Plus foncé au survol */
    text-decoration: underline;
  }
  
  .logout-link:active {
    color: #1e7e34; /* Encore plus foncé au clic */
  }
  
  .account-selector {
    margin: 10px 0;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
  }
  
  .account-selector label {
    font-size: 0.9rem;
    color: #ccc;
    margin-bottom: 5px;
  }
  
  .account-selector select {
    background-color: #1a1a2e;
    color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    font-size: 0.9rem;
  }
  
  .account-selector select:focus {
    outline: none;
    border-color: #28a745; /* Vert moderne */
  }
  
  .account-selector .collapsed-label {
    display: none;
  }
  