/* Container styles */
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.auth-box {
  background-color: #1a1a2e;
  color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.auth-box h1 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.auth-link {
  color: #21c87a; 
  cursor: pointer;
  font-weight: 500;
}

.auth-link:hover {
  text-decoration: underline;
}

form {
  margin-top: 20px;
  text-align: left;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-size: 0.9rem;
  margin-bottom: 5px;
  display: block;
}

input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #303030;
  background-color: #2a2a3e;
  color: white;
}

input[type="email"]::placeholder,
input[type="password"]::placeholder {
  color: #777;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.password-wrapper {
  display: flex;
  align-items: center;
}

.password-wrapper input {
  flex: 1;
}

.password-wrapper .toggle-password {
  margin-left: 10px;
  cursor: pointer;
  color: #777;
}

.error-message {
  color: #ff4d4d;
  font-size: 0.85rem;
  margin-top: -10px;
  margin-bottom: 10px;
}

.auth-btn {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  background-color: #21c87a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.auth-btn:hover {
  background-color: #1ab269;
}

@media (max-width: 768px) {
  .auth-box {
    padding: 20px;
  }

  .auth-box h1 {
    font-size: 1.25rem;
  }

  .auth-btn {
    font-size: 0.9rem;
    padding: 10px;
  }
}
