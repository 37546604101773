* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  display: flex;
  justify-content: center;
  height: 100vh;
  background-color: #f9f9f9;
}

.container {
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  flex-direction: row; /* Default to side-by-side */
}

.left {
  width: 60%;
  padding: 40px;
}

.right {
  width: 40%;
  padding: 40px;
  background-color: #f1f1f1;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

h1 span {
  font-weight: normal;
}

p {
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  margin-bottom: 10px;
}

input {
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 12px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

hr {
  margin: 20px 0;
  border: 0;
  height: 1px;
  background-color: #ddd;
}

/* Add media query for responsive design */
@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Stacks the blocks vertically */
    padding: 20px;
  }

  .left, .right {
    width: 100%; /* Each block takes the full width */
    padding: 20px;
  }

  h1 {
    font-size: 2rem;
  }

  button {
    padding: 10px;
    font-size: 1rem;
  }
}


p em {
  color: #9C9C9C;
}
